import { Component, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { BrowserDetectorService } from './services/browser-detector.service';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { SecurityService } from './services/security.service';
import {
  AccountInfo,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { PERMISSIONS_DICTIONARY } from './utils/permissions-dictionary';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { Ripple } from 'primeng/ripple';
import { NETSAPIENS_MODULES } from './utils/netsapiens-modules';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    ConfirmDialogModule,
    ToastModule,
    IconFieldModule,
    InputIconModule,
    TieredMenuModule,
    CommonModule,
    NgOptimizedImage,
    InputTextModule,
    Ripple,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  constructor(
    private primeNGConfig: PrimeNGConfig,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private browserDetectorService: BrowserDetectorService,
    private securityService: SecurityService
  ) {}

  isMobile = false;
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  items: MenuItem[] | undefined;
  menuExpanded = true;

  toggleMenu(): void {
    this.menuExpanded = !this.menuExpanded;
  }

  async ngOnInit() {
    this.isMobile = this.browserDetectorService.isMobile();
    this.primeNGConfig.ripple = true;

    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    this.defineUserPermissions(this.authService.instance.getActiveAccount());
  }

  defineUserPermissions(account: AccountInfo | null): void {
    if (account) {
      this.securityService.getSecurityProfile().then(() => {
        const itemsTemplate = [
          {
            label: 'Dashboard',
            icon: 'pi pi-desktop',
            route: 'dashboard',
            enabled: true,
          },
          {
            separator: true,
            enabled: true,
          },
          {
            label: 'Domains',
            icon: NETSAPIENS_MODULES.domains.icon,
            route: 'domains',
            enabled: this.securityService.hasEntityAssigned(
              PERMISSIONS_DICTIONARY.domains.name
            ),
          },
          {
            label: 'Numbers',
            icon: NETSAPIENS_MODULES.numbers.icon,
            route: 'numbers',
            enabled: this.securityService.hasEntityAssigned(
              PERMISSIONS_DICTIONARY.numbers.name
            ),
          },
          {
            label: 'Sites',
            icon: NETSAPIENS_MODULES.sites.icon,
            route: 'sites',
            enabled: this.securityService.hasEntityAssigned(
              PERMISSIONS_DICTIONARY.sites.name
            ),
          },
          {
            label: 'Users',
            icon: NETSAPIENS_MODULES.users.icon,
            route: 'users',
            enabled: this.securityService.hasEntityAssigned(
              PERMISSIONS_DICTIONARY.users.name
            ),
          },
          {
            label: 'Trunks',
            icon: NETSAPIENS_MODULES.sipTrunks.icon,
            route: 'trunks',
            enabled: this.securityService.hasEntityAssigned(
              PERMISSIONS_DICTIONARY.trunks.name
            ),
          },
          {
            separator: true,
            enabled: true,
          },
          {
            label: 'Prov Wizard',
            icon: 'pi pi-sparkles',
            route: 'provision/wizards',
            enabled: true,
          },
        ];
        this.items = itemsTemplate.filter((item) => item.enabled);
      });
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
