import {
  CreateDomainResponse,
  CreatePhonenumberResponse,
  CreateUserResponse,
  ErrorResponse,
  PostDomainsByDomainSitesResponse,
} from '../netsapiens';
import {
  DomainConfig,
  NetsapiensPhoneNumber,
  NetsapiensSite,
  NetsapiensUser,
} from './voice-models';

export interface WizardInstance {
  wizardId?: string;
  domainId: string;
  reseller: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  wizardType?: WizardType;
  status?: WizardStatus;
  progress: WizardProgress;
  steps?: ProvisioningSteps;
  errors?: ErrorTrace;
  domain?: DomainConfig;
  numbers?: NetsapiensPhoneNumber[];
  sites?: NetsapiensSite[];
  users?: NetsapiensUser[];
}

export interface WizardProgress {
  progress: number;
  verified: boolean;
  enqueued?: boolean;
  domain: boolean;
  numbers: boolean;
  sites: boolean;
  users: boolean;
  remediation?: WizardRemediationProgress;
}

export interface WizardRemediationProgress {
  sites: boolean;
  users: boolean;
  numbers: boolean;
  verified: boolean;
  remediated?: boolean;
}

export enum WizardConfig {
  domain = 'domain',
  numbers = 'numbers',
  sites = 'sites',
  users = 'users',
}

export enum WizardType {
  provision = 'provision',
  migration = 'migration',
}

export enum WizardStepKey {
  domain = 'domain',
  numbers = 'numbers',
  sites = 'sites',
  users = 'users',
  verify = 'verify',
  provision = 'provision',
}

export interface WizardStep {
  wizardKey: WizardStepKey;
  title: string;
  icon: string;
  touched?: boolean;
  valid?: boolean;
  disabled?: boolean;
  nextDisabled?: boolean;
  backDisabled?: boolean;
}

export interface SerializedWizardJSONProperties {
  progress: string;
  domain?: string;
  numbers?: string;
  sites?: string;
  users?: string;
}

export interface ProvisioningSteps {
  completed: number;
  total: number;
}

export enum WizardStatus {
  ENQUEUED = 'ENQUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export type ErrorTrace = {
  domain?: DomainConfig;
  numbers?: NetsapiensPhoneNumber[];
  sites?: NetsapiensSite[];
  users?: NetsapiensUser[];
};

export type NetsapiensData =
  | DomainConfig
  | NetsapiensSite
  | NetsapiensUser
  | NetsapiensPhoneNumber;

export type Return =
  | CreateUserResponse
  | PostDomainsByDomainSitesResponse
  | CreateDomainResponse
  | CreatePhonenumberResponse
  | { error: ErrorResponse };
