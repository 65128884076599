import { RequestResult } from '@hey-api/client-axios';
import {
  DialruleApplication,
  DomainsService,
  ErrorResponse,
  PhoneNumbersService,
  Scopes,
  SitesService,
  Synchronous,
  UsersService,
} from '../../netsapiens';
import { NetsapiensData, Return } from '../../models/wizard-models';
import { removeWhiteSpaces } from '../text/text.utils';

export enum ServiceKey {
  domain = 'DOMAIN',
  site = 'SITE',
  user = 'USER',
  phone = 'PHONE',
}
const createMap: {
  [key: string]: (
    params: NetsapiensData,
    domainId?: string
  ) => RequestResult<Return>;
} = {
  DOMAIN: async (params) =>
    await DomainsService.createDomain({
      body: {
        ...params,
        synchronous: Synchronous.YES,
        domain: params.domain ?? '',
        reseller: 'reseller' in params ? params.reseller : '~',
      },
    }),
  SITE: async (params, domainId) =>
    await SitesService.postDomainsByDomainSites({
      path: { domain: domainId ?? '' },
      body: {
        ...params,
        synchronous: Synchronous.YES,
        site:
          'site' in params && params.site ? removeWhiteSpaces(params.site) : '',
        'login-username':
          'site' in params && params.site
            ? `${removeWhiteSpaces(params.site)}@${domainId}`
            : '',
      },
    }),
  USER: async (params, domainId) =>
    await UsersService.createUser({
      path: {
        domain: domainId ?? '',
      },
      body: {
        user:
          'extension' in params && params.extension
            ? params?.extension.toString()
            : '',
        'name-first-name': 'firstName' in params ? params.firstName : '',
        'name-last-name': 'lastName' in params ? params.lastName : '',
        'email-address': 'email' in params ? params.email : '',
        'user-scope': Scopes.BASIC_USER,
        site: 'site' in params ? params.site : '',
      },
    }),
  PHONE: async (params, domainId) => {
    const user =
      'dialRuleTranslationDestinationUser' in params &&
      params.dialRuleTranslationDestinationUser
        ? params.dialRuleTranslationDestinationUser.trim()
        : '';
    return PhoneNumbersService.createPhonenumber({
      path: {
        domain: domainId ?? '',
      },
      body: {
        phonenumber:
          'phoneNumber' in params && params.phoneNumber
            ? params.phoneNumber
            : '',
        'dial-rule-application':
          user === ''
            ? DialruleApplication.AVAILABLE_NUMBER
            : DialruleApplication.TO_USER,
        'dial-rule-translation-destination-user': user,
      },
    });
  },
};
export function isError(response: Return): response is ErrorResponse {
  const error = response as ErrorResponse;
  return !!(error.code && error.message);
}
export async function create(
  serviceKey: ServiceKey,
  body: NetsapiensData | NetsapiensData[],
  domainId?: string
): Promise<NetsapiensData | NetsapiensData[] | null> {
  const service = createMap[serviceKey];
  if (!service) {
    throw new Error('Service not enabled');
  }

  if (!Array.isArray(body)) {
    const response = await service(body, domainId);
    if ('error' in response && isError(response.error as ErrorResponse)) {
      return {
        ...body,
        error: response.error as ErrorResponse
      };
    } else {
      return null;
    }
  }

  const responses = await Promise.allSettled(body.map(async (b) => service(b)));

  const errors = responses
    .map((response, index) => {
      if (response.status === 'rejected') {
        return {
          ...body[index],
          error: {
            code: 400,
            message: JSON.stringify(response.reason),
          },
        };
      }
      if (response.status === 'fulfilled') {
        if (
          'error' in response.value &&
          isError(response.value.error as ErrorResponse)
        ) {
          return {
            ...body[index],
            error: response.value.error as ErrorResponse,
          };
        }
      }
      return;
    })
    .filter((response) => !!response);

  if (errors.length > 0) {
    return errors;
  }
  return null;
}
