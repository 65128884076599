import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './components/failed/failed.component';
import { PermissionsGuard } from './guards/PermissionsGuard';

export const appRoutes: Route[] = [
  {
    path: 'dashboard',
    canActivate: [MsalGuard],
    loadComponent: () =>
      import('./components/dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'domains',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/domains/domains.component').then(
        (c) => c.DomainsComponent
      ),
  },
  {
    path: 'domain/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/domain/domain.component').then(
        (c) => c.DomainComponent
      ),
  },
  {
    path: 'sites',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/sites/sites.component').then(
        (c) => c.SitesComponent
      ),
  },
  {
    path: 'users',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/users/users.component').then(
        (c) => c.UsersComponent
      ),
  },
  {
    path: 'numbers',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/numbers/numbers.component').then(
        (c) => c.NumbersComponent
      ),
  },
  {
    path: 'trunks',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/trunks/trunks.component').then(
        (c) => c.TrunksComponent
      ),
  },
  {
    path: 'provision/wizards',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/wizards/provision-wizards/provision-wizards.component').then(
        (c) => c.WizardsComponent
      ),
  },
  {
    path: 'provision/wizard',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/wizards/wizard/wizard.component').then(
        (c) => c.WizardComponent
      ),
  },
  {
    path: 'provision/wizard/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/wizards/wizard/wizard.component').then(
        (c) => c.WizardComponent
      ),
  },
  {
    path: 'provision/remediation-wizard/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/wizards/wizard-remediation/wizard-remediation.component').then(
        (c) => c.WizardRemediationComponent
      ),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
