/** Migration Constants */
export const DOMAIN_DATA_TYPE = 'Meta_Global_DomainInformation';
export const DEPARTMENTS_DATA_TYPE = 'Msrb_BusinessGroup_ChildrenList_Department';
export const SUBSCRIBERS_DATA_TYPE = 'Msrb_BusinessGroup_ChildrenList_Subscriber';
export const AUTO_ATTENDANT_USER_TYPE = 'SmartVoice_AA';
/** Domain Data Constants */
export const DOMAIN_DEFAULT_RESIDENTIAL = 'no';
export const DOMAIN_DEFAULT_STATUS = 'active';
export const DOMAIN_DEFAULT_SSO = 'no';
export const DOMAIN_DEFAULT_DIAL_PERMISSION = 'us-canada';
export const DOMAIN_DEFAULT_TIMEZONE = 'US/Pacific';
export const DOMAIN_DEFAULT_AREA_CODE = 0;
export const DOMAIN_DEFAULT_RMOH = 'no';
export const DOMAIN_DEFAULT_SERVER = 'Core1';
export const DOMAIN_DEFAULT_VOICEMAIL = true;
export const USER_DEFAULT_SCOPE = 'Basic User';
export const PHONE_DEFAULT_ENABLED = 'false';
export const PHONE_USER_DIAL_RULE_APP = 'user';
